<template>
  <div class="subBg">
     <section class="w1314">
       <div class="snavi">
         <!--img src="@/assets/img/snavhomeicon.png" />
         <span class="ml10 mr10">{{$t('front.common.cscenter')}}</span><img src="@/assets/img/snavicon.png" />
         <span class="ml10" :class="{'ml10': $t(breadcrumbText2) }">{{ $t(breadcrumbText1) }}</span>
         <template v-if="breadcrumbText2">
           <img src="@/assets/img/snavicon.png" />
         </template>
         <span class="ml10 b" v-if="breadcrumbText2">{{ $t(breadcrumbText2) }}</span-->
       </div>

       <h2 class="subBanner"><img src="@/assets/img/icon_vvs.svg" />{{$t('front.common.mypage')}}<!--img src="@/assets/img/subBanner3.png" alt=""/--></h2>
       <div class="boardwrap">
         <nav-side :currentMenu="currentName" :menuList="menuList" v-if="currentName !== 'stributor' "/>
         <transition name="fade">
           <router-view />
         </transition>
       </div>
     </section>
  </div>

</template>

<script>
import NavSide from '@/components/common/NavSide'
import { mapState } from 'vuex'

export default {
  name: 'Mypage',
  components: {
    NavSide
  },
  data () {
    return {
      currentName: '',
      breadcrumbText1: '',
      breadcrumbText2: '',
      menuList: []
    }
  },
  watch: {
    $route: {
      handler () {
        this.getCurrentName()
      }
    }
  },
  computed: {
    ...mapState([
      'userData'
    ])
  },
  created () {
    this.getCurrentName()
    this.setMenuList()
  },
  methods: {
    getCurrentName () {
      this.breadcrumbText1 = ''
      this.breadcrumbText2 = ''
      if (this.$route.matched) {
        const count = this.$route.matched.length === 4 ? 2 : 1
        const item = this.$route.matched[this.$route.matched.length - count]
        if (item) {
          this.currentName = item.name
        }
        this.breadcrumbText1 = item.meta.text
        const currentMenuText = this.$route.matched[this.$route.matched.length - 1].meta.text
        if (this.breadcrumbText1 !== currentMenuText) {
          this.breadcrumbText2 = currentMenuText
        }
      }
    },
    setMenuList () {
      const list = this.$router.getRoutes()

      for (let i = 0, iLen = list.length; i < iLen; i++) {
        const item = list[i]
        const routeName = item.name
        if (routeName === 'mypage') {
          const children = item.children
          for (let j = 0, jLen = children.length; j < jLen; j++) {
            const subItem = children[j]
            if (subItem.meta.lnb) {
              if (subItem.name.indexOf('stributor') > -1) {
                if (this.userData.isPartnerMember && this.userData.isPartnerMember === 'Y') {
                  this.menuList.push(subItem)
                }
              } else if (subItem.name.indexOf('give') > -1) {
                if (this.userData.cashSendYn && this.userData.cashSendYn === 'Y') {
                  this.menuList.push(subItem)
                }
              } else {
                this.menuList.push(subItem)
              }
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
